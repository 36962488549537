@zindex-link-navigation: 9000;

@import './components/header/styles.less';

// remove paddding on aem shared header

#link-navigation.link-navigation {
  &.header-inline {
    .link-navigation__wrapper__content {
      ul {
        padding: 0;
      }
    }
  }
}
