// novotel

.has-emergency #link-navigation.link-navigation.header-inline .link-navigation__nav__menu {
  padding: 0!important;
}

body:has(#link-navigation.link-navigation):has(.link-navigation__mainWrapper):has(.link-navigation__primaryHeader--nov) .app-banner{
  display: none;
}

body.homepage {
  #link-navigation.link-navigation {
    &:has(.link-navigation__mainWrapper):has(.link-navigation__primaryHeader--nov) {
        height: auto !important;
    }

    &.header-inline {
        .link-navigation__primaryHeader {
            &--nov {    
              height: auto;           
              background-color: #1E22AA;
              color: #fff;

              .connect-zone {
                  .loyalty-card {
                    background-color: #f2f0e2;
                  }
                  .login-nav__list-items {
                    .login-nav__item--round__button {
                      background-color: #1E22AA;

                      &__clear {
                        &:hover {
                          background-color: #1E22AA;
                          border: solid 1px #1E22AA;
                          color: #fff;
                        }
                      }
                    }
                  }
                }
  
              #link-navigation.link-navigation span
              .link-navigation__nav__item span {
                color: #fff;
              }

              .link-navigation__nav__menu {
                background-color: #fff;

                @media screen and (min-width: 1024px) {
                  background-color: #1E22AA;
                  color: #fff;
                }
              }

              .link-navigation__primaryHeader__wrapperHead {
                background-color: #1E22AA;
                border-bottom: 0;
              }

              .burgerMenu .link-navigation__menu-letters {
                color: #fff; 
              }

              .burgerMenu__icon {
                &::after, 
                &::before {
                  background-color: #fff;
                } 
              }

              .link-navigation__nav__menu .link-navigation__n2menu {
                svg > circle {
                  fill: #fff;
                }
              }

              .link-navigation__panel.link-navigation__opened-n2 {
                //top: 32px;
                @media screen and (min-width: 1024px) {
                  //top: 87px;
                }
              }
             
              .mainLogo__nov__svg {
                fill: #fff;
              }



              .burgerMenu {
                background-color: #1E22AA;
                  &__icon {
                    &::after,
                    &::before {
                      background-color: #fff;
                    }
                }
              }


              .link-navigation__nav__menu {
                .link-navigation__nav__item {
                  &:focus,
                  &:hover {
                    background-color: #1E22AA;
                    color: #fff;
  
                    &::before {
                      background: #fff;
                    }
                  }
  
                  @media screen and (max-width: 1023px) {
                    &:focus,
                    &:hover {
                      background-color: transparent;

                      .icon-sprites-link-navigation {
                        color: #1264a3!important
                      }
                    }
                  }
  
                  span {
                      @media screen and (min-width: 1024px) {
                        background-color: #1E22AA;
                        color: #fff!important;
                    }
                  }
                } 

                .icon-sprites-link-navigation {
                
                  @media screen and (min-width: 1024px) {
                    color: #fff!important;
                  }
                }

                #link-navigation__panel__more {
                  span {
                    color: #1E1852!important;
                  }
                }
              }

              .js-more-item .link-navigation__panel__top .link-navigation__content .link-navigation__col ul li.link-navigation__menu .link-navigation__menu__link span {
                background-color: #fff;
              }

              .js-more-item .link-navigation__panel__top .link-navigation__content .link-navigation__col ul li.link-navigation__menu .link-navigation__menu__link {
                background-color: #fff;
                :hover {
                  background-color: #fff;
                }
              }

              .dependentdropdown__title,
              .dependentdropdown__label {
                color: #252339;
              }

              .link-navigation__nav__item:focus span,
              .link-navigation__nav__item:hover span {

                    @media screen and (min-width: 1024px) {
                      color: #fff!important;
                    }
                }

                .link-navigation__nav__menu .link-navigation__menu .link-navigation__active::before {
                  background: #fff!important;
                }             
            }
          }
      }
    }
}


#link-navigation.link-navigation {
    &:has(.link-navigation__mainWrapper):has(.link-navigation__primaryHeader--nov) {
      height: auto !important;
    }
    &.header-inline {
        .link-navigation__primaryHeader {
            &--nov {
                height: auto;
                
                .connect-zone {
                  .loyalty-card {
                    background-color: #f2f0e2;
                  }
                  .login-nav__list-items{
                    .login-nav__item--round__button {
                      background-color: #1E22AA;

                      &__clear{
                        &:hover{
                          background-color: #1E22AA;
                          border: solid 1px #1E22AA;
                        }
                      }
                    }
                  }
                }
                .burger-menu-btn-wrapper {
                    margin-left: 0px;
                    @media screen and (min-width: 1024px) {
                      margin-left: 45px;
                    }
                }
                .link-navigation__panel__title {
                    padding-left: 2rem;
                    + .link-navigation__content {
                        padding-bottom: 48px;
                    }
                }
                .mainLogo__nov__svg {
                    fill: #1E22AA;
                    height: auto;
                    width: auto;
                }

                
                .button-logo__button--disconnected {
                    max-width: 100%;
                }
                .button-logo__text {
                    font-size: initial!important;
                }

                .link-navigation__n2menu.link-navigation__active span,
                .link-navigation__nav__item span {
                  color: #3C3C3C;
                }

                .link-navigation__panel__column .link-navigation__content {
                  .link-navigation__col ul li {

                    a.link-navigation__last-link span {
                      color: #1E22AA;
                    }

                    .link-navigation__accordion__header
                    .link-navigation__accordion__title span {
                      font-weight: 700;
                    }
                  }
                }

                .link-navigation__nav__menu .link-navigation__n2menu:after {
                  height: 10px;
                  width: 10px;
                }

                .link-navigation__nav__menu .link-navigation__menu.link-navigation__permanent .link-navigation__menu__link {
                  border-bottom: 0;
                }

                .link-navigation__nav__menu .link-navigation__nav__item span {
                  font-size: 18px;
                }


                .link-navigation__wrapper__content ul,
                .link-navigation__nav__menu-list.js-nav-list {
                  padding-left: 0;

                  @media screen and (min-width: 1024px) {
                    padding-left: 40px;
                }
              }

                .link-navigation__nav__menu {
                  top: 25px;

                  @media screen and (min-width: 768px) {
                    //border-top: 0;
                    overflow: hidden;
                    top: 0;
                }

                .link-navigation__nav__item {
  
                  @media screen and (max-width: 1023px) {
                    &:focus,
                    &:hover {
                      .icon-sprites-link-navigation {
                        color: #1264a3!important
                      }
                    }
                  }
                } 
              } 

              .link-navigation__panel.link-navigation__opened-n2 {
                top: 33px;
                @media screen and (min-width: 1024px) {
                  top: 100%;
                }
              }

              .link-navigation__primaryHeader__wrapperHead {
                //border-bottom: 0;
                padding: 13px 0;
                background-color: #fff;
                
                @media screen and (min-width: 1024px) {
                  //background-color: #1E22AA;
                  border-bottom: 1px solid #d7e0e5;
                  padding: 0 3.3%;
                }
              }

              .link-navigation__panel__column--wpt {
              
                @media screen and (max-width: 1023px) {
                  padding: 0 0 30px 0;
                }
                .link-navigation__content {
                  border-top: 1px solid  #DADADD;

                  @media screen and (min-width: 1024px) {
                    border-top: 0;
                  }
                }
            }

              .button-logo__button--disconnected {
                background: #1e22aa;
                border: solid 1px #fff;
              }


              .link-navigation__wrapper {
                @media only screen and (min-width: 768px) and (max-width: 1023px) {
                  top: 5.6rem;
                }
              }

              #link-navigation.link-navigation .link-navigation__panel__column .link-navigation__content .link-navigation__col .link-navigation__accordion > div.link-navigation__minus-m > ul {
                display: block;
              }

              .dependentdropdown__btn {
                background-color: #1E22AA!important;
              
                &:hover {
                  background-color: #1A1E95!important;
                }
              
                &:active {
                  background-color:#171A80!important;
                }
              } 

          }  
        }
    }   
}
