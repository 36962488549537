@import '@accor/base-style/components/header/styles';
@import './styles/partials/_inline-header-custom-brands';
// fix for LR waiting for the correction on LR side

@version-desktop: ~'only screen and (min-width: 1024px)';

.topHeader {
  height: auto!important;
  &__image {
    height: 3.8125rem!important;  
  }

  #link-navigation.link-navigation.header-inline {
    @media @version-desktop {
      height: 84px;
    }
  }

  > img {
    @media @version-desktop {
      position:absolute;
      z-index: 9;
    }
  }

  .topHeader__container {
    @media @version-desktop {
      display: block!important;
    }
  }
    >a {
      @media @version-desktop {
        height:3.8125rem!important;
        display:flex;
      }
    +.topHeader__container {
      @media @version-desktop {
        display: none!important;
      }
    }
  }
}

// fix for Loyalty team
.header-inline.link-navigation{
  + #loyalty-navbar.loyalty-navbar {
    @media @version-desktop {
      min-height: auto
    }
  }
}
.loyalty-navbar {
  .loyalty-navbar__wrapper {
    @media @version-desktop {
      top: 4.8em;
    }
  }
}
#main .sticky-nav {
  @media @version-desktop {
    top: 5.2em;
  }
}
